import consumer from "./consumer"

export const subToBroadcastChannel = async (props, receivedCallback, subCallback) => {
  return new Promise( (resolve, reject) => {
    const sub = consumer.subscriptions.create({ channel: "BroadcastChannel", event_id: props.eventId, company_id: props.companyId  }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Broadcast channel connected");
        resolve();
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Broadcast channel disconnected");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log("Broadcast data", data);
        receivedCallback(data);
      },

      sendMessage: function(message) {
        return this.perform('message', {message});
      },

      refreshCounter: function() {
        this.perform('refresh_counter');
      },

      leave() {
        console.log("Leave broadcast chat");
        this.unsubscribe();
      },
    });
    subCallback(sub);
  });
};
