import consumer from "./consumer"

export const subToQueueChannel = async (props, receivedCallback, subCallback) => {
  return new Promise( (resolve, reject) => {
    const sub = consumer.subscriptions.create({ channel: "QueueChannel" ,event_id: props.eventId, company_id: props.companyId }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Queue channel connected");
        this.getQueue();
        resolve();
      },

      getQueue() {
        this.perform('get_queue');
      },

      peerCompleted(peerId) {
        this.perform('peer_completed', { peer_id: peerId });
      },

      peerReview(peerId) {
        this.perform('peer_review', { peer_id: peerId });
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Queue channel disconnected");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        receivedCallback(data);
      }
    });
    subCallback(sub);
  });
}
