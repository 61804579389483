import consumer from "./consumer"

export const subToChatChannel = async (props, receivedCallback, subCallback) => {
  return new Promise( (resolve, reject) => {
    const sub = consumer.subscriptions.create({ channel: "ChatChannel", event_id: props.eventId, company_id: props.companyId } , {
      connected() {
        // Called when the subscription is ready for use on the server
        if( props.recruiter ) {
          console.log("Chat channel connected as a recruiter");
        } else {
          console.log("Chat channel connected as a jobseeker");
          this.join();
        }
        resolve(); // promise
      },

      join() {
        console.log("Join waiting list");
        this.perform("join", { event_id: props.eventId, company_id: props.companyId } );
      },

      sendMessage(to, message) {
        this.perform('send_message', { to, message });
      },

      sendStatus(peer, val) {
        this.perform('send_status', { peer, val } );
      },

      connectToPeer(peer) { // recruiter exclusive
        console.log('#connect_to_peer', peer);
        this.perform('connect_to_peer', { peer });
      },

      disconnectFromPeer(peer) { // recruiter exclusive
        console.log('#disconnect_from_peer', peer);
        this.perform('disconnect_from_peer', { peer });
      },

      reconnect() { // jobseeker exclusive
        console.log("#sending reconnect signal");
        this.perform('reconnect');
      },

      leave() {
        console.log("Leave chat");
        this.perform("leave");
        this.unsubscribe();
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Chat channel disconnected");
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log(data);
        receivedCallback(data);
      }
    });
    subCallback(sub);
  });
}
